import { computed, ref } from '@vue/composition-api'
import { getServerTimezoneInfo } from '../utils/api/server-timezone.js'

const serverTimezoneState = ref(null)
let isLoading = false

const load = async () => {
	if (isLoading) return
	isLoading = true
	const { data: serverTimezone } = await getServerTimezoneInfo()
	serverTimezoneState.value = serverTimezone
	isLoading = false
}

export const serverTimezone = computed(() => {
	if (serverTimezoneState.value === null) {
		load()
		return []
	}
	return serverTimezoneState.value
})
