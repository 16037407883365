import { format } from 'date-fns'
import { configApi } from '../http-client.js'
import { DATE_FORMAT } from '../date-formatting.js'

const DEFAULT_LANGUAGE = 0

export const getStatements = () => {
	return configApi.get('api/Statements').then(({ data: { statements } }) => JSON.parse(JSON.stringify(({
		data: statements.map(statement => ({
			...statement,
			statementId: statement.statementID,
			status: statement.defaultLangStatus,
			statementText: statement.statementText ?? '',
			createdDate: format(new Date(statement.earliestCreatedDate), DATE_FORMAT),
			liveStatementText: statement.liveStatements?.find(({ languageId }) => languageId === DEFAULT_LANGUAGE)?.statementText,
			draftStatementText: statement.draftStatements?.find(({ languageId }) => languageId === DEFAULT_LANGUAGE)?.statementText,
			defaultLanguageDraftStatement: statement.draftStatements?.find(({ languageId }) => languageId === DEFAULT_LANGUAGE),
			metaData: statement.metaData.map(({ keyId, keyName, value }) => ({
				metaDataKeyId: keyId,
				metaDataKeyName: keyName,
				metaDataValue: value
			})),
			archivedStatements: statement.archiveStatements.map(archived => ({
				...archived,
				archivedOn: format(new Date(archived.archivedOn), DATE_FORMAT)
			}))
		}))
	}))))
}

export const putStatementAction = statement => {
	return configApi.put('api/Statements/StatementAction', statement)
}

export const postStatementAndAction = statementAndAction => {
	return configApi.post('api/Statements/StatementAndAction', statementAndAction)
}

export const postStatement = statement => {
	return configApi.post('api/Statements', statement)
}

export const putStatement = statement => {
	return configApi.put('api/Statements', statement)
}

export const deleteStatement = statementID => configApi.delete(`/api/Statements/${statementID}`)

export const upsertStatementTranslation = statementTranslation => configApi.post('api/Statements/UpsertStatementTranslation', statementTranslation)

export const deleteStatementTranslation = statementTranslation => configApi.delete(`/api/Statements/StatementTranslation/${statementTranslation.statementId}/${statementTranslation.languageId}`)
