<template>
	<ValidationForm
		#default="{ handleSubmit }"
		tag="div"
		class="cassie-vertical-md"
	>
		<ViewLayout>
			<template #header-title>
				{{ pageTitle }}
			</template>
			<template #header-caption>
				{{ 'Add, edit and manage statement translations.' | useLabels }}
			</template>
			<template #header-after>
				<slot name="header-after" />
			</template>
			<template #content>
				<SectionCard>
					<template #title>
						{{ 'Default Statement Text' | useLabels }}
					</template>
					<template #body>
						<v-row dense>
							<v-col cols="12">
								<Textarea
									:value="statementTranslation.statementText"
									:label="'Default Statement' | useLabels"
									view-only
								/>
							</v-col>
						</v-row>
					</template>
				</SectionCard>
				<SectionCard>
					<template #title>
						{{ 'Live Statement Translation Details' | useLabels }}
					</template>
					<template #body>
						<span v-if="showStatementDrafts && !statementTranslation.liveTranslatedStatementText">
							No live Version
						</span>
						<v-row
							v-else
							dense
						>
							<v-col cols="12">
								<Textarea
									v-model="statementTranslation.liveTranslatedStatementText"
									:label="'Live Statement Translation' | useLabels"
									:view-only="showStatementDrafts || universalStatementNotUser"
								/>
							</v-col>
						</v-row>
					</template>
				</SectionCard>
				<SectionCard v-if="showStatementDrafts">
					<template #title>
						{{ 'Draft Statement Translation Details' | useLabels }}
					</template>
					<template #title-action>
						{{ getDraftStatementStatus }}
					</template>
					<template #body>
						<v-row dense>
							<v-col cols="12">
								<Textarea
									v-model="statementTranslation.draftTranslatedStatementText"
									:view-only="(!canCreateDraftStatements || (!displaySubmitButton && !displaySaveDraftButton) || universalStatementNotUser) || !canInteractWithDraftStatements"
									:label="'Draft Statement Details' | useLabels"
								/>
							</v-col>
						</v-row>
						<v-row dense>
							<v-col
								cols="6"
								class="cassie-horizontal-sm"
							>
								<DeleteButton
									v-if="displayAbandonButton && canCreateDraftStatements && canInteractWithDraftStatements"
									@click="putStatementAction(STATEMENT_ACTIONS.ABANDON)"
								>
									Abandon
								</DeleteButton>
								<DeleteButton
									v-if="displayRejectDraftButton && canApproveDraftStatements & canInteractWithDraftStatements"
									@click="putStatementAction(STATEMENT_ACTIONS.REJECT_DRAFT)"
								>
									Reject Draft
								</DeleteButton>
								<DeleteButton
									v-if="displayRejectApprovalButton && canPublishDraftStatements && canInteractWithDraftStatements"
									@click="putStatementAction(STATEMENT_ACTIONS.REJECT_APPROVAL)"
								>
									Reject Approval
								</DeleteButton>
								<DeleteButton
									v-if="displayCancelPublishButton && canPublishDraftStatements"
									@click="putStatementAction(STATEMENT_ACTIONS.CANCEL_PUBLISH)"
								>
									Cancel Publish
								</DeleteButton>
							</v-col>
							<v-col
								cols="6"
								class="text-right cassie-horizontal-sm d-flex flex-row"
							>
								<v-spacer />
								<PrimaryActionButton
									v-if="displaySaveDraftButton && canCreateDraftStatements && canInteractWithDraftStatements"
									@click="handleSubmit(saveLiveStatementTranslation(true))"
								>
									Save
								</PrimaryActionButton>
								<PrimaryActionButton
									v-if="displaySubmitButton && canCreateDraftStatements && canInteractWithDraftStatements"
									@click="putStatementAction(STATEMENT_ACTIONS.SUBMIT)"
								>
									Submit for Approval
								</PrimaryActionButton>
								<PrimaryActionButton
									v-if="displayApproveButton && canApproveDraftStatements && canInteractWithDraftStatements"
									@click="putStatementAction(STATEMENT_ACTIONS.APPROVE)"
								>
									Approve
								</PrimaryActionButton>
								<PrimaryActionButton
									v-if="displayPublishButton && canPublishDraftStatements && canInteractWithDraftStatements"
									@click="putStatementAction(STATEMENT_ACTIONS.PUBLISH)"
								>
									Publish Now
								</PrimaryActionButton>
								<PrimaryActionButton
									v-if="displayPublishButton && canPublishDraftStatements && canInteractWithDraftStatements"

									@click=" showPublishOnModal=true"
								>
									Schedule
								</PrimaryActionButton>
							</v-col>
						</v-row>
						<PublishOnModal
							v-if="showPublishOnModal"
							@close="() =>
								showPublishOnModal = false
							"
							@handleClick="(date) => putStatementAction(STATEMENT_ACTIONS.PUBLISH_ON, date)"
						/>
					</template>
				</SectionCard>
				<SectionCard>
					<template #title>
						{{ 'Archived Statement Versions'| useLabels }}
					</template>
					<template #body>
						<DataTable
							:headers="tableHeaders"
							:items="archivedStatements"
						/>
					</template>
				</SectionCard>
			</template>
			<template #footer>
				<PageActionRow>
					<template #actions>
						<SecondaryActionButton @click="backToOverview">
							Back to overview
						</SecondaryActionButton>
						<v-spacer />
						<PrimaryActionButton
							v-if="canInteractWithDraftStatements && !showStatementDrafts"
							@click="handleSubmit(saveLiveStatementTranslation(true))"
						>
							{{ isEdit ? 'Save' : 'Create' }}
						</PrimaryActionButton>
					</template>
				</PageActionRow>
			</template>
		</ViewLayout>
	</ValidationForm>
</template>
<script>
import { mapGetters } from 'vuex'
import { format } from 'date-fns'
import { DATE_TIME_FORMAT_NO_SECONDS } from '../../../../../../shared/utils/date-formatting.js'
import ViewLayout from '../../../../../../shared/layouts/view-layout.vue'
import ValidationForm from '../../../../../../shared/components/validation-form.vue'
import SectionCard from '../../../../../../shared/components/section-card.vue'
import Textarea from '../../../../../../shared/components/textarea.vue'
import DataTable from '../../../../../../shared/components/data-table.vue'
import PrimaryActionButton from '../../../../../../shared/components/primary-action-button.vue'
import SecondaryActionButton from '../../../../../../shared/components/secondary-action-button.vue'
import DeleteButton from '../../../../../../shared/components/delete-button.vue'
import PageActionRow from '../../../../../../shared/components/page-action-row.vue'
import { STATEMENTS } from '../../../../router/route-names.js'
import { showStatementDrafts } from '../../../../../../shared/state/config-keys.js'
import { upsertStatementTranslation, putStatementAction } from '../../../../../../shared/utils/api/statements.js'
import { STATEMENT_ACTIONS, STATEMENT_STATUS } from '../statement-enums.js'
import { CAN_CREATE_DRAFT_STATEMENTS, CAN_APPROVE_DRAFT_STATEMENTS, CAN_PUBLISH_DRAFT_STATEMENTS, COMPONENTS_MODULE_FULL_PERMISSIONS, STATEMENTS_FULL_PERMISSIONS } from '../../../../../../shared/permissions/admin-portal-permissions.js'
import { useLabels } from '../../../../../../shared/state/admin-portal-navigation.js'
import PublishOnModal from '../../../shared/publish-on-modal.vue'
export default {
	components: { ValidationForm, ViewLayout, SectionCard, Textarea, DataTable, PrimaryActionButton, SecondaryActionButton, DeleteButton, PageActionRow, PublishOnModal },
	props: {
		statementTranslationToEdit: Object,
		channelName: String,
		accessedVia: String,
		languageName: String
	},
	setup () {
		return {
			showStatementDrafts
		}
	},
	data () {
		const ACCESSED_VIA_STATEMENTS = 'statements'
		const ACCESSED_VIA_CHANNEL = 'channel'
		return {
			ACCESSED_VIA_STATEMENTS,
			ACCESSED_VIA_CHANNEL,
			STATEMENT_ACTIONS,
			STATEMENT_STATUS,
			statementTranslation: JSON.parse(JSON.stringify(this.statementTranslationToEdit || {
				statementText: '',
				liveTranslatedStatementText: '',
				draftTranslatedStatementText: '',
				metaData: []
			})),
			showPublishOnModal: false
		}
	},
	computed: {
		...mapGetters('auth', ['productAreaPermission', 'userHasUniversalBrand']),
		isEdit () {
			return Boolean(this.statementTranslationToEdit.liveTranslatedStatementText || this.statementTranslationToEdit.draftTranslatedStatementText)
		},
		pageTitle () {
			if (this.universalStatementNotUser) {
				return `${this.channelName} - View ${useLabels('Statement')} Translation (${this.languageName})`
			}
			return `${this.channelName} - ${!this.isEdit ? 'Add' : 'Edit'} ${useLabels('Statement')} Translation (${this.languageName})`
		},
		canInteractWithDraftStatements () {
			if (showStatementDrafts.value === false) {
				return this.productAreaPermission(COMPONENTS_MODULE_FULL_PERMISSIONS) || this.productAreaPermission(STATEMENTS_FULL_PERMISSIONS)
			}
			return (this.productAreaPermission(CAN_PUBLISH_DRAFT_STATEMENTS) ||
				this.productAreaPermission(CAN_CREATE_DRAFT_STATEMENTS) ||
				this.productAreaPermission(CAN_APPROVE_DRAFT_STATEMENTS))
		},
		canCreateDraftStatements () {
			return this.productAreaPermission(CAN_CREATE_DRAFT_STATEMENTS)
		},
		canApproveDraftStatements () {
			return this.productAreaPermission(CAN_APPROVE_DRAFT_STATEMENTS)
		},
		canPublishDraftStatements () {
			return this.productAreaPermission(CAN_PUBLISH_DRAFT_STATEMENTS)
		},
		displaySaveDraftButton () {
			return (!this.statementTranslation.selectedLanguageDraftStatement || this.statementTranslation.selectedLanguageDraftStatement.draftState === STATEMENT_STATUS.NEW) &&
				!this.universalStatementNotUser
		},
		displaySubmitButton () {
			return this.statementTranslation.selectedLanguageDraftStatement?.draftState === STATEMENT_STATUS.NEW &&
				!this.universalStatementNotUser
		},
		displayAbandonButton () {
			return this.statementTranslation.selectedLanguageDraftStatement && this.statementTranslation.selectedLanguageDraftStatement.draftState === STATEMENT_STATUS.NEW &&
				!this.universalStatementNotUser
		},
		displayRejectDraftButton () {
			return this.statementTranslation.selectedLanguageDraftStatement && this.statementTranslation.selectedLanguageDraftStatement.draftState === STATEMENT_STATUS.SUBMITTED &&
				!this.universalStatementNotUser
		},
		displayRejectApprovalButton () {
			return this.statementTranslation.selectedLanguageDraftStatement && this.statementTranslation.selectedLanguageDraftStatement.draftState === STATEMENT_STATUS.APPROVED &&
				!this.universalStatementNotUser
		},
		displayCancelPublishButton () {
			return this.statementTranslation.selectedLanguageDraftStatement &&
				this.statementTranslation.selectedLanguageDraftStatement.draftState === STATEMENT_STATUS.AWAITING_PUBLISH && !this.universalStatementNotUser
		},
		displayApproveButton () {
			return this.statementTranslation.selectedLanguageDraftStatement && this.statementTranslation.selectedLanguageDraftStatement.draftState === STATEMENT_STATUS.SUBMITTED &&
				!this.universalStatementNotUser
		},
		displayPublishButton () {
			return this.statementTranslation.selectedLanguageDraftStatement &&
				(this.statementTranslation.selectedLanguageDraftStatement.draftState === STATEMENT_STATUS.APPROVED ||
					this.statementTranslation.selectedLanguageDraftStatement.draftState === STATEMENT_STATUS.AWAITING_PUBLISH) &&
				!this.universalStatementNotUser
		},
		getDraftStatementStatus () {
			if (!this.statementTranslation.selectedLanguageDraftStatement || this.statementTranslation.selectedLanguageDraftStatement.draftState === STATEMENT_STATUS.NEW) return 'Current Status: New'
			if (this.statementTranslation.selectedLanguageDraftStatement.draftState === STATEMENT_STATUS.SUBMITTED) return 'Current Status: Submitted'
			if (this.statementTranslation.selectedLanguageDraftStatement.draftState === STATEMENT_STATUS.APPROVED) return 'Current Status: Approved'
			if (this.statementTranslation.selectedLanguageDraftStatement.draftState === STATEMENT_STATUS.AWAITING_PUBLISH) return 'Current Status: Live on ' + format(new Date(this.statementTranslation.selectedLanguageDraftStatement.queuedActionDateTime), DATE_TIME_FORMAT_NO_SECONDS)
			else return ''
		},
		tableHeaders () {
			return [
				{
					text: useLabels('Statement Text'),
					value: 'statementText',
					width: '80%'
				},
				{
					text: 'Archive Date',
					value: 'archivedOn',
					width: '20%'
				}
			]
		},
		archivedStatements () {
			return this.statementTranslation.archivedStatements.filter(({ languageId }) => languageId === this.statementTranslation.languageId)
		},
		universalStatementNotUser () {
			return this.statementTranslationToEdit.brandID === 0 && !this.userHasUniversalBrand
		}
	},
	created () {
		this.scrollToTop()
	},
	methods: {
		scrollToTop () {
			if (this.accessedVia === this.ACCESSED_VIA_CHANNEL) {
				document.querySelector('#manage_channel_statement_container').scrollTo(0, 0)
			}
		},
		backToOverview () {
			this.scrollToTop()
			if (this.accessedVia === this.ACCESSED_VIA_CHANNEL) {
				this.$emit('close')
				this.$emit('load-channel-statements')
			} else {
				this.$router.push({
					name: STATEMENTS,
					params: {
						accessedVia: 'statement-translations',
						previouslySelectedLanguage: this.statementTranslation.languageId
					}
				})
			}
		},
		async saveLiveStatementTranslation (goBackToOverview) {
			const translation = {
				languageId: this.statementTranslation.languageId,
				statementTranslationText: showStatementDrafts.value ? this.statementTranslation.draftTranslatedStatementText : this.statementTranslation.liveTranslatedStatementText,
				statementId: this.statementTranslation.statementId
			}
			await upsertStatementTranslation(translation)
			if (goBackToOverview) {
				this.backToOverview()
			}
		},
		async putStatementAction (actionId, autoQueueDateTime) {
			let statementAction = {
				svid: this.statementTranslation.translationSvid,
				action: actionId
			}
			if (actionId === STATEMENT_ACTIONS.SUBMIT) {
				await this.saveLiveStatementTranslation(false)
			}
			if (actionId === STATEMENT_ACTIONS.PUBLISH_ON) {
				statementAction = {
					svid: this.statementTranslation.translationSvid,
					action: actionId,
					autoQueueDateTime: autoQueueDateTime
				}
			}
			await putStatementAction(statementAction)
			this.backToOverview()
		}
	}
}
</script>
