<template>
	<ValidationForm
		#default="{ handleSubmit }"
	>
		<Modal
			width="460px"
		>
			<template #modal-title>
				Pick Published On Date
				<v-spacer />
				<IconButton
					@click="close"
				>
					mdi-close
				</IconButton>
			</template>
			<template #modal-content>
				<div class="date-picker-container">
					<v-date-picker
						v-model="selectedDate"
						:min="minDate"
						:width="410"
					/>
				</div>
				<div>
					<Dropdown
						v-model="selectedTime"
						:items="populateHours"
						rules=""
						label="Time"
					/>
				</div>
				<div>
					Please Note: Times are in {{ serverTimeAndUtcOffset }}
				</div>
			</template>
			<template #modal-footer>
				<SecondaryActionButton
					class="action-button"
					@click="close"
				>
					Cancel
				</SecondaryActionButton>
				<PrimaryActionButton
					class="action-button"
					style="font-size: 12px;"
					@click="handleSubmit(handleClick)"
				>
					Confirm
				</PrimaryActionButton>
			</template>
		</Modal>
	</ValidationForm>
</template>
<script>
import { format, add } from 'date-fns'
import Modal from '../../../../shared/components/modal.vue'
import IconButton from '../../../../shared/components/icon-button.vue'
import PrimaryActionButton from '../../../../shared/components/primary-action-button.vue'
import SecondaryActionButton from '../../../../shared/components/secondary-action-button.vue'
import { DATE_FORMAT_DATE_PICKER } from '../../../../shared/utils/date-formatting.js'
import { serverTimezone } from '../../../../shared/state/server-timezone.js'
import Dropdown from '../../../../shared/components/dropdown.vue'
import ValidationForm from '../../../../shared/components/validation-form.vue'
const tomorrow = format(add(new Date(), { days: 1 }), DATE_FORMAT_DATE_PICKER)
const yesterday = format(add(new Date(), { days: -1 }), DATE_FORMAT_DATE_PICKER)

export default {
	components: {
		Modal,
		IconButton,
		PrimaryActionButton,
		SecondaryActionButton,
		Dropdown,
		ValidationForm
	},
	setup () {
		return {
			serverTimezone
		}
	},
	data () {
		return {
			selectedDate: tomorrow,
			minDate: yesterday,
			selectedTime: '00:00'
		}
	},
	computed: {
		autoQueueDateTime () {
			return this.selectedDate.concat(' ' + this.selectedTime)
		},
		serverTimeAndUtcOffset () {
			if (this.serverTimezone.utcOffset == null) return ''
			const conditionalPlus = this.serverTimezone.utcOffset > 0 ? '+' : ''
			return this.serverTimezone.serverTimezone.concat(' (UTC ' + conditionalPlus + this.serverTimezone.utcOffset + ')')
		},
		populateHours () {
			const h = []
			for (let i = 0; i < 24; i++) {
				h.push({ value: i.toString().padStart(2, '0') + ':00', text: i.toString().padStart(2, '0') + ':00' })
			}
			return h
		}
	},
	async created () {
		await this.serverTimezone
	},
	methods: {
		close () {
			this.$emit('close')
		},
		handleClick () {
			this.$emit('handleClick', this.autoQueueDateTime)
		}
	}
}
</script>
<style lang="scss">
.date-picker-container {
	display: flex;
	justify-content: center;
	padding: 0px;
}
</style>
