export const STATEMENT_ACTIONS = {
	SUBMIT: 100,
	REJECT_DRAFT: 200,
	APPROVE: 300,
	REJECT_APPROVAL: 400,
	PUBLISH_ON: 500,
	CANCEL_PUBLISH: 600,
	PUBLISH: 700,
	ABANDON: 800
}

export const STATEMENT_STATUS = {
	NEW: 100,
	SUBMITTED: 200,
	APPROVED: 300,
	AWAITING_PUBLISH: 400
}
